<template>
  <div>
    <b-row>
      <b-col sm="4">
        <!----------------- Total loan amount line chart card Start ---------------->
        <statistic-card-with-area-chart
          :statistic-title="statisticTitleName"
          :chart-data="statisticChartData.series"
        />
        <!----------------- Total loan amount line chart card End ---------------->
      </b-col>
      <b-col sm="8">
        <!----------------- Total member card Start ---------------->
        <card-statistics-group
          :statistic-title="$t('total members')"
          :statistics-items="statisticsItems"
        />
        <!----------------- Total member card End ---------------->
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header>
            <h5>{{ $t('member list') }}</h5>
          </b-card-header>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="px-2"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-------------------------- Group filter Dropdwon Start --------------------------->
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="filterGroupItems.find(d => d.key === group).name"
                    variant="outline-primary"
                    class="w-100 mb-1 full-width-dropdown"
                  >
                    <b-dropdown-item
                      v-for="item in filterGroupItems"
                      :key="item.key"
                      @click="handleGroup(item.key)"
                    >
                      {{ item.name }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-------------------------- Group filter Dropdwon End --------------------------->
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-------------------------- Status filter Dropdwon Start -------------------------->
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :text="$t(filterStatusItems.find(d => d.key === status).name)"
                    variant="outline-primary"
                    class="w-100 mb-1 full-width-dropdown"
                  >
                    <b-dropdown-item
                      v-for="item in filterStatusItems"
                      :key="item.key"
                      @click="handleStatus(item.key)"
                    >
                      {{ $t(item.name.charAt(0).toLowerCase() + item.name.slice(1)) }}
                    </b-dropdown-item>
                  </b-dropdown>
                  <!-------------------------- Status filter Dropdwon End -------------------------->
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="px-2 mb-2"
            >
              <b-row class="">
                <b-col
                  cols="10"
                  md="8"
                  class="offset-md-3"
                >
                  <!-------------------------- Search box Start -------------------------->
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchQuery"
                      :placeholder="$t('search') + '...'"
                    />
                  </b-input-group>
                  <!-------------------------- Search box End -------------------------->
                </b-col>

                <b-col
                  cols="1"
                  class=""
                >
                  <!--
                      Button to add a customer
                    -->
                  <b-button
                    v-if="$can('create', 'customers')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.modal-add
                    variant="primary"
                    class="btn-icon"
                    @click="handleAddCustomer"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12">
              <b-table
                id="all-table"
                responsive
                :items="filteredRows"
                :fields="customersTableInfo.fields"
                primary-key="basicInfo.accountNumber"
                :filter="searchQuery"
                :per-page="perPage"
                :current-page="currentPage"
                table-class="custom-table"
                thead-class="text-nowrap"
              >
                <!------------------- Status column ----------------->
                <template #cell(status)="data">
                  <div style="width: 90px;">
                    <b-badge
                      v-if="statusItems.find(d => d.key === data.value)"
                      :variant="`light-${statusItems.find(d => d.key === data.value).color}`"
                      class="circle-rounded px-1 font-weight-normal mb-50"
                    >
                      {{ $t(statusItems.find(d => d.key === data.value).name.toLowerCase()) }}
                    </b-badge>
                  </div>
                </template>
                <!------------------- User column ------------------->
                <template #cell(user)="data">
                  <b-media
                    no-body
                    style="width: 130px;"
                  >
                    <b-media-aside
                      class="mr-1"
                    >
                      <b-avatar
                        :src="data.value.avatar"
                      />
                    </b-media-aside>
                    <b-media-body class="my-auto text-left">
                      <b-link
                        :to="{ path: 'customer', query: { id: data.item.id } }"
                      >
                        <h6 class="mb-0 text-primary text-nowrap">
                          {{ data.value.name }}
                        </h6>
                      </b-link>
                      <b-card-text class="font-small-3 mb-0">
                        {{ data.value.group }} {{ data.value.accountId }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </template>
                <template #cell(nextPayment)="data">
                  <div v-if="data.item.nextPayment">
                    {{ data.item.nextPayment | moment('YYYY/MM/DD') }}
                  </div>
                </template>
                <template #cell(lendDate)="data">
                  <div v-if="data.item.lendDate">
                    {{ data.item.lendDate | moment('YYYY/MM/DD') }}
                  </div>
                </template>
                <!------------------- Rate column ------------------->
                <template #cell(rate)="data">
                  {{ data.value + ' %' }}
                </template>
                <!------------------- TotalLoan column -------------->
                <template #cell(totalLoan)="data">
                  {{ `$ ${numberWithCommas(data.value)}` }}
                </template>
                <template #cell(penalty)="data">
                  {{ `$ ${numberWithCommas(data.value)}` }}
                </template>

                <template #cell(promissoryNote)="data">
                  {{ `$ ${numberWithCommas(data.value)} (${$t(data.item.promissoryNotesStatus)})` }}
                </template>

                <!------------------- Action column ----------------->
                <template #cell(action)="data">
                  <b-dropdown
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    toggle-class="px-0 py-50 bg-transparent"
                    variant="flat-dark"
                    no-caret
                    right
                    @show="customerSelectHandle(data.item.id)"
                  >
                    <template
                      v-slot:button-content
                    >
                      <feather-icon
                        :id="`box-pop-menu-${data.item.id}`"
                        icon="MoreVerticalIcon"
                        size="18"
                        class="cursor-pointer"
                      />
                    </template>
                    <!-- <b-dropdown-item
                        v-b-modal.customer-payment-edit-modal
                      >
                        {{ $t('payment') }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-b-modal.customer-loan-edit-modal
                      >
                        {{ $t('loan') }}
                      </b-dropdown-item> -->
                    <b-dropdown-item
                      @click="editClickHandle(data.item.id)"
                    >
                      {{ $t('edit') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
                <template #head()="data">
                  <div style="min-width: 30px;">
                    {{ $t(data.label) }}
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col cols="6">
              <b-card-text class="font-small-3 mb-0 ml-1">
                {{ totalRows === 0 ? `Showing 0 - ${totalRows} of ${totalRows} entries` :
                  perPage*(currentPage-1)+perPage > totalRows ?
                    `Showing ${perPage*(currentPage-1)+1} - ${totalRows} of ${totalRows} entries` :
                    `Showing ${perPage*(currentPage-1)+1} - ${perPage*(currentPage-1)+perPage} of ${totalRows} entries`
                }}
              </b-card-text>
            </b-col>
            <b-col cols="6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="right"
                size="sm"
                class="mt-0 mb-1 mr-1"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!--
        Modal to edit the payment of each customer in the customer table
      -->
    <b-modal
      id="customer-payment-edit-modal"
      title="Lending"
      ok-title="Save"
      cancel-title="cancel"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
    >
      <b-form-group
        label="Date"
        label-for="lending-datepicker"
      >
        <b-form-datepicker
          id="lending-datepicker"
          class="mb-1"
        />
      </b-form-group>
      <b-form-group
        label="Amount"
        label-for="lending-amount"
      >
        <b-input-group
          id="lending-amount"
          prepend="$"
          append=".00"
          class="input-group-merge"
        >
          <b-form-input placeholder="Amount" />
        </b-input-group>
      </b-form-group>
    </b-modal>

    <!--
        Modal to edit the loan of each customer in the customer table
      -->
    <b-modal
      id="customer-loan-edit-modal"
      title="Loan"
      ok-title="Save"
      cancel-title="cancel"
      cancel-variant="outline-primary"
      footer-class="justify-content-end flex-row-reverse"
      centered
      @ok="loanEditHandle"
    >
      <b-form-group
        label="Date"
        label-for="loan-datepicker"
      >
        <b-form-datepicker
          id="loan-datepicker"
          class="mb-1"
          @input="onChangeLoanDate"
        />
      </b-form-group>
      <b-form-group
        label="Amount"
        label-for="loan-amount"
      >
        <b-input-group
          id="loan-amount"
          prepend="$"
          append=".00"
          class="input-group-merge"
        >
          <b-form-input
            placeholder="Amount"
            @change="onChangeLoanAmount"
          />
        </b-input-group>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import store from '@/store'
import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BCardHeader, BCardText, BDropdown, BDropdownItem, BFormGroup, BInputGroup, BFormInput, BButton, BTable, BBadge, BMedia, BMediaAside, BMediaBody, BAvatar, BLink, BPagination, BModal, VBModal, BFormDatepicker, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import _ from 'lodash'
import StatisticCardWithAreaChart from './components/StatisticCardWithAreaChart.vue'
import CardStatisticsGroup from './components/CardStatisticsGroup.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BLink,
    BPagination,
    BModal,
    BFormDatepicker,
    BInputGroupPrepend,
    StatisticCardWithAreaChart,
    CardStatisticsGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      searchQuery: '',
      customers: [],
      statisticChartData: {
        series: [
          {
            name: 'Revenue',
            data: [],
          },
        ],
        analyticsData: {
          total: 0,
        },
      },
      statisticsItems: [
        {
          icon: 'TrendingUpIcon',
          color: 'light-primary',
          title: 'processing',
          value: 0,
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'UserIcon',
          color: 'light-danger',
          title: 'bad debt',
          value: 0,
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'BoxIcon',
          color: 'light-warning',
          title: 'observed',
          value: 0,
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: 'closed',
          value: 0,
          customClass: '',
        },
      ],
      groupItems: [
        {
          key: 'all',
          name: 'All',
        },
        {
          key: 'group1',
          name: 'Group 1',
        },
        {
          key: 'group2',
          name: 'Group 2',
        },
        {
          key: 'group3',
          name: 'Group 3',
        },
      ],
      statusItems: [
        {
          key: 'processing',
          name: 'Processing',
          color: 'primary',
          icon: 'TrendingUpIcon',
        },
        {
          key: 'observed',
          name: 'Observed',
          color: 'warning',
          icon: 'BoxIcon',
        },
        {
          key: 'closed',
          name: 'Closed',
          color: 'success',
          icon: 'DollarSignIcon',
        },
        {
          key: 'bad_debt',
          name: 'bad debt',
          color: 'danger',
          icon: 'UserIcon',
        },
        {
          key: 'active',
          name: 'Active',
          color: 'info',
          icon: 'UserIcon',
        },
        {
          key: 'inactive',
          name: 'Inactive',
          color: 'secondary',
        },
        {
          key: 'dishonored',
          name: 'dishonored',
          color: 'danger',
        },
      ],
      customersTableInfo: {
        fields: [
          { key: 'status', label: 'status' },
          { key: 'user', label: 'user' },
          { key: 'phone', label: 'phone', class: 'text-center' },
          { key: 'rate', label: 'interest rate', class: 'text-right' },
          {
            key: 'nextPayment',
            label: 'next payment',
            sortable: true,
            class: 'text-center',
          },
          { key: 'duration', label: 'duration', class: 'text-center' },
          {
            key: 'lendDate',
            label: 'lend date',
            sortable: true,
            class: 'text-center',
          },
          {
            key: 'totalLoan',
            label: 'total loan',
            sortable: true,
            class: 'text-right',
          },
          {
            key: 'penalty', label: 'penalty', class: 'text-right', sortable: true,
          },
          {
            key: 'promissoryNote',
            label: 'promissory note',
            class: 'text-center',
            tdClass: 'text-nowrap',
          },
          {
            key: 'action',
            label: 'action',
            class: 'text-center',
          },
        ],
      },
      group: 'all',
      status: 'all',
      perPage: 25,
      pageOptions: [3, 5, 10],
      totalRows: 0,
      currentPage: 1,
      customerIdHandled: '',
      customerLoan: {
        date: '',
        amount: 0,
      },
      saveSpinner: false,
      statisticTitleName: this.$t('total loan amount'),
    }
  },
  computed: {
    ...mapGetters({
      getCustomers: 'app/getCustomers',
      user: 'getUser',
    }),
    filterStatusItems() {
      return [
        {
          key: 'all',
          name: 'All',
        },
        ...Object.keys(_.groupBy(_.filter(this.customers, customer => customer.status !== 'none'), 'status')).map(key => ({
          key,
          name: this.statusItems.find(d => d.key === key) ? this.statusItems.find(d => d.key === key).name : '',
        })),
      ]
    },
    filterGroupItems() {
      return [
        {
          key: 'all',
          name: 'All',
        },
        ...Object.keys(_.groupBy(this.customers, 'group')).map(key => ({
          key,
          name: key.charAt(0).toUpperCase() + key.slice(1),
        })),
      ]
    },
    filteredRows() {
      let filtered = []
      if (this.group !== 'all' && this.status !== 'all') {
        filtered = _.filter(this.customers, { group: this.group, status: this.status })
      } else if (this.group === 'all' && this.status !== 'all') {
        filtered = _.filter(this.customers, { status: this.status })
      } else if (this.group !== 'all' && this.status === 'all') {
        filtered = _.filter(this.customers, { group: this.group })
      } else {
        filtered = [...this.customers]
      }
      return filtered
    },
  },
  watch: {
    getCustomers(newVal) {
      this.setCustomers(newVal)
    },
    customers(newVal) {
      if (newVal && newVal.length > 0) {
        const groupByStatus = _.groupBy(newVal, 'status')
        const summaryStatus = this.statusItems.map(item => ({
          icon: item.icon,
          color: `light-${item.color}`,
          title: item.name,
          value: groupByStatus[item.key] ? groupByStatus[item.key].length : 0,
          customClass: 'mb-2 mb-xl-0',
        }))
        this.$set(this, 'statisticsItems', summaryStatus)
        this.$set(this, 'statisticChartData', {
          series: [{
            name: 'Loan amount',
            data: newVal.map(d => d.totalLoan),
          }],
          analyticsData: {
            total: newVal.map(d => d.totalLoan).reduce((a, b) => a.totalLoan + b.totalLoan) || 0,
          },
        })
      }
    },
    filteredRows(newVal) {
      if (this.status === 'bad_debt') this.statisticTitleName = this.$t('total bad debit')
      this.$set(this, 'statisticChartData', {
        series: [{
          name: 'Loan amount',
          data: newVal.map(d => d.totalLoan),
        }],
        analyticsData: {
          total: newVal.map(d => d.totalLoan).reduce((a, b) => a.totalLoan + b.totalLoan) || 0,
        },
      })
      this.$set(this, 'totalRows', newVal.length)
    },
  },
  created() {
    store.dispatch('app/getCustomersFromDb', { user: this.user })
  },
  mounted() {
    if ((!this.customers || this.customers.length === 0) && this.getCustomers.length > 0) {
      this.setCustomers(this.getCustomers)
    }
  },
  methods: {
    setCustomers(customers) {
      const filterCustomers = _.sortBy(customers, ['basicInfo.accountNumber']).map(customer => {
        const row = {
          id: customer.id,
          status: customer.status,
          group: customer.basicInfo.group,
          user: {
            name: customer.basicInfo.fullName,
            accountId: customer.basicInfo.accountNumber,
            group: customer.group,
            avatar: customer.photoURL,
          },
          phone: customer.basicInfo.cellPhoneNumber,
          rate: customer.debtInfo.interestRate,
          nextPayment: customer.loan.dueDateTime,
          duration: customer.debtInfo.paymentDuration,
          lendDate: customer.loan.loanDateTime,
          loanDate: customer.loan.loanDateTime,
          totalLoan: customer.loan.principal,
          promissoryNote: customer.debtInfo.promissoryNotesAmount,
          promissoryNotesStatus: customer.debtInfo.promissoryNoteStatus,
          currency: '$',
          address: customer.basicInfo.address,
          penalty: customer.loan.penalty,
          idNumber: customer.basicInfo.idNumber,
          companyName: customer.basicInfo.companyName,
        }
        return row
      })
      this.$set(this, 'customers', filterCustomers)
    },
    handleStatus(key) {
      this.$set(this, 'status', key)
    },
    handleGroup(key) {
      this.$set(this, 'group', key)
    },
    handleAddCustomer() {
      this.$router.push({ name: 'customer-register' })
    },
    editClickHandle(customerId) {
      this.$router.push({
        path: 'customer',
        query: {
          id: customerId,
          section: 'basicInfo',
        },
      })
    },
    onChangeLoanDate(e) {
      this.customerLoan.date = e
    },

    onChangeLoanAmount(e) {
      this.customerLoan.amount = e
    },
    loanEditHandle() {
      const rawCustomer = store.state.app.customers.find(d => d.id === this.customerIdHandled)
      this.$set(this, 'saveSpinner', true)
      store.dispatch('app/updateCustomerWithIdFromDb', {
        id: this.customerIdHandled,
        update: {
          loan: {
            ...rawCustomer.loan,
            loanDateTime: this.customerLoan.date,
            principal: rawCustomer.loan.principal + parseInt(this.customerLoan.amount, 10),
          },
        },
      }).then(res => {
        if (res.status === 'success') {
          this.$bvToast.toast(`A loan of a customer-${rawCustomer.basicInfo.fullName} was updated successfully.`, {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        } else {
          this.$bvToast.toast(`Failed. ${res.error}`, {
            title: 'Failed',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-top-center',
          })
        }
        this.$set(this, 'saveSpinner', false)
      })
    },

    customerSelectHandle(cid) {
      this.$set(this, 'customerIdHandled', cid)
    },
  },
}
</script>
